import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { Link, graphql } from 'gatsby';
import style from './blog.module.scss';
import { Layouts, Button, SocialButton } from '../components/components';
import { strapiSelector } from '../redux/strapi/strapi.selectors';
import { pathTo } from '../lib/utils';
import { FontWeight } from '../lib/constants/enums';
import { StrapiArticlesConnection } from '../graphql-types';
import { LatestArticles } from '../modules/modules';

interface BlogProps {
  data: { articles: StrapiArticlesConnection };
}

const BlogPage: React.FC<BlogProps> = ({ data }) => {
  const { IndexLayout, Container, LayoutBG, AdaptiveLayout } = Layouts;
  const {
    strapi: { blogCategories },
  } = useSelector(strapiSelector);

  const howManyArticles = (route: string) => {
    let counter = 0;

    data.articles.edges.forEach(item => {
      if (item.node.blog_categories.find((elem: any) => elem.routeName === route)) counter++;
    });

    return counter;
  };

  return (
    <IndexLayout>
      <div className={style.bloPromoWrapper}>
        <AdaptiveLayout onDesktop onTablet>
          <LayoutBG
            className={style.blogPromo}
            background={`url(${process.env.STRAPI_API_URL}/uploads/blog_1_min_f66b65939d.png) top right / cover no-repeat`}
          >
            <Container className={style.blogContainer}>
              <h2 className={style.promoTitle}>Читай полезные статьи в блоге</h2>
              <h5 className={style.promoInfo}>Новый контент 3 раза в неделю</h5>
              <a className={style.promoLink} href="https://subscribe.goslim.pro/subpage-subscribe_to_our_mailing_list" target="_blank">
                <Button title="Подписаться на рассылку" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
              <a className={style.promoLink} href="https://api.whatsapp.com/send?phone=79654582905&text=" target="_blank">
                <SocialButton icon="whatsapp" title="Задать вопрос в WhatsApp" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
              <a className={style.promoLink} href="https://t.me/KatyaMedushkina_bot" target="_blank">
                <SocialButton icon="telegram" title="Задать вопрос в Telegram" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
            </Container>
          </LayoutBG>
        </AdaptiveLayout>
        <AdaptiveLayout onMobile>
          <LayoutBG
            className={style.blogPromo}
            background={`url(${process.env.STRAPI_API_URL}/uploads/Frame_45_99f6f3de01.png) center top / cover no-repeat`}
          >
            <Container className={style.blogContainer}>
              <h2 className={style.promoTitle}>Читай полезные статьи в блоге</h2>
              <h5 className={style.promoInfo}>Новый контент 3 раза в неделю</h5>
              <a className={style.promoLink} href="https://subscribe.goslim.pro/subpage-subscribe_to_our_mailing_list" target="_blank">
                <Button title="Подписаться" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
              <a className={style.promoLink} href="https://api.whatsapp.com/send?phone=79654582905&text=" target="_blank">
                <SocialButton icon="whatsapp" title="Задать вопрос в WhatsApp" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
              <a className={style.promoLink} href="https://t.me/KatyaMedushkina_bot" target="_blank">
                <SocialButton icon="telegram" title="Задать вопрос в Telegram" border fontSize={14} fontWeight={FontWeight.bold} />
              </a>
            </Container>
          </LayoutBG>
        </AdaptiveLayout>
      </div>
      <Container className={style.blogContainer}>
        <div className={style.blogListWrap}>
          <h3 className={style.blogTitle}>Выбирай по какому направлению тебе интересно почитать:</h3>
          {blogCategories ? (
            <div className={style.blogList}>
              {blogCategories.map((category, index) => {
                return (
                  <Link to={pathTo(`blog/${category.routeName}`)} className={style.category} key={category.id || index}>
                    {category.theme && (
                      <div
                        className={style.categoryColor}
                        style={{
                          background: category?.theme.mainColor || 'none',
                          backgroundSize: 'cover',
                          backgroundImage: `url(${
                            category.theme.backgroundImage ? `${process.env.STRAPI_API_URL}${category.theme.backgroundImage.url}` : 'none'
                          })`,
                        }}
                      />
                    )}
                    <div className={style.categoryContent}>
                      <div className={style.categoryName}>
                        <div>{category.name}</div>
                        <div className={style.articlesQty}>{howManyArticles(category.routeName)}</div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <div className={style.blogLoader}>
              <Spin />
            </div>
          )}
        </div>
      </Container>
      <LatestArticles data={data.articles.edges.map(el => el.node)} />
    </IndexLayout>
  );
};

export default BlogPage;
export const pageQuery = graphql`
  query {
    articles: allStrapiArticles {
      edges {
        node {
          blog_categories {
            routeName
          }
          id
          picture {
            publicURL
          }
          created_at
          published_at
          routeName
          title
          content
        }
      }
    }
  }
`;
